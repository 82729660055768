/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-page-container-children-content {
  margin: 24px 24px 0;
}
.ant-pro-page-container-warp {
  background-color: #fff;
}
.ant-pro-page-container-warp .ant-tabs-nav {
  margin: 0;
}
.ant-pro-page-container-ghost .ant-pro-page-container-warp {
  background-color: transparent;
}
.ant-pro-page-container-ghost .ant-pro-page-container-children-content {
  margin-top: 0;
}
.ant-pro-page-container-main .ant-pro-page-container-detail {
  display: flex;
}
.ant-pro-page-container-main .ant-pro-page-container-row {
  display: flex;
  width: 100%;
}
.ant-pro-page-container-main .ant-pro-page-container-title-content {
  margin-bottom: 16px;
}
.ant-pro-page-container-main .ant-pro-page-container-title,
.ant-pro-page-container-main .ant-pro-page-container-content {
  flex: auto;
  width: 100%;
}
.ant-pro-page-container-main .ant-pro-page-container-extraContent,
.ant-pro-page-container-main .ant-pro-page-container-main {
  flex: 0 1 auto;
}
.ant-pro-page-container-main .ant-pro-page-container-main {
  width: 100%;
}
.ant-pro-page-container-main .ant-pro-page-container-title {
  margin-bottom: 16px;
}
.ant-pro-page-container-main .ant-pro-page-container-logo {
  margin-bottom: 16px;
}
.ant-pro-page-container-main .ant-pro-page-container-extraContent {
  min-width: 242px;
  margin-left: 88px;
  text-align: right;
}
@media screen and (max-width: 1200px) {
  .ant-pro-page-container-main .ant-pro-page-container-extraContent {
    margin-left: 44px;
  }
}
@media screen and (max-width: 992px) {
  .ant-pro-page-container-main .ant-pro-page-container-extraContent {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .ant-pro-page-container-main .ant-pro-page-container-row {
    display: block;
  }
  .ant-pro-page-container-main .ant-pro-page-container-action,
  .ant-pro-page-container-main .ant-pro-page-container-extraContent {
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  .ant-pro-page-container-detail {
    display: block;
  }
  .ant-pro-page-container-extraContent {
    margin-left: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-grid-content {
  width: 100%;
}
.ant-pro-grid-content.wide {
  max-width: 1200px;
  margin: 0 auto;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-footer-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  line-height: 44px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-pro-footer-bar-left {
  flex: 1;
}
.ant-pro-footer-bar-right > * {
  margin-right: 8px;
}
.ant-pro-footer-bar-right > *:last-child {
  margin: 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-basicLayout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.ant-pro-basicLayout .ant-layout-header.ant-pro-fixed-header {
  position: fixed;
  top: 0;
}
.ant-pro-basicLayout-content {
  position: relative;
  margin: 24px;
}
.ant-pro-basicLayout-content .ant-pro-page-container {
  margin: -24px -24px 0;
}
.ant-pro-basicLayout-content-disable-margin {
  margin: 0;
}
.ant-pro-basicLayout-content-disable-margin .ant-pro-page-container {
  margin: 0;
}
.ant-pro-basicLayout-content > .ant-layout {
  max-height: 100%;
}
.ant-pro-basicLayout .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: 100vh;
  overflow: hidden;
  transform: rotate(0);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 48px - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children {
  min-height: calc(100vh - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: calc(100vh - 48px);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-fixed-header {
  z-index: 9;
  width: 100%;
}
.ant-pro-fixed-header-action {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-basicLayout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.ant-pro-basicLayout .ant-layout-header.ant-pro-fixed-header {
  position: fixed;
  top: 0;
}
.ant-pro-basicLayout-content {
  position: relative;
  margin: 24px;
}
.ant-pro-basicLayout-content .ant-pro-page-container {
  margin: -24px -24px 0;
}
.ant-pro-basicLayout-content-disable-margin {
  margin: 0;
}
.ant-pro-basicLayout-content-disable-margin .ant-pro-page-container {
  margin: 0;
}
.ant-pro-basicLayout-content > .ant-layout {
  max-height: 100%;
}
.ant-pro-basicLayout .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: 100vh;
  overflow: hidden;
  transform: rotate(0);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 48px - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children {
  min-height: calc(100vh - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: calc(100vh - 48px);
}
.ant-pro-global-header {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.ant-pro-global-header > * {
  height: 100%;
}
.ant-pro-global-header-collapsed-button {
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-size: 20px;
}
.ant-pro-global-header-layout-mix {
  background-color: #001529;
}
.ant-pro-global-header-layout-mix .ant-pro-global-header-logo h1 {
  color: #fff;
}
.ant-pro-global-header-layout-mix .anticon {
  color: #fff;
}
.ant-pro-global-header-logo {
  position: relative;
  overflow: hidden;
}
.ant-pro-global-header-logo a {
  display: flex;
  align-items: center;
  height: 100%;
}
.ant-pro-global-header-logo a img {
  height: 28px;
}
.ant-pro-global-header-logo a h1 {
  height: 32px;
  margin: 0 0 0 8px;
  margin: 0 0 0 12px;
  color: #1890ff;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}
.ant-pro-global-header-menu .anticon {
  margin-right: 8px;
}
.ant-pro-global-header-menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.ant-pro-global-header .dark {
  height: 48px;
}
.ant-pro-global-header .dark .action {
  color: rgba(255, 255, 255, 0.85);
}
.ant-pro-global-header .dark .action > i {
  color: rgba(255, 255, 255, 0.85);
}
.ant-pro-global-header .dark .action:hover,
.ant-pro-global-header .dark .action.opened {
  background: #1890ff;
}
.ant-pro-global-header .dark .action .ant-badge {
  color: rgba(255, 255, 255, 0.85);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-basicLayout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.ant-pro-basicLayout .ant-layout-header.ant-pro-fixed-header {
  position: fixed;
  top: 0;
}
.ant-pro-basicLayout-content {
  position: relative;
  margin: 24px;
}
.ant-pro-basicLayout-content .ant-pro-page-container {
  margin: -24px -24px 0;
}
.ant-pro-basicLayout-content-disable-margin {
  margin: 0;
}
.ant-pro-basicLayout-content-disable-margin .ant-pro-page-container {
  margin: 0;
}
.ant-pro-basicLayout-content > .ant-layout {
  max-height: 100%;
}
.ant-pro-basicLayout .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: 100vh;
  overflow: hidden;
  transform: rotate(0);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 48px - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children {
  min-height: calc(100vh - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: calc(100vh - 48px);
}
.ant-pro-sider {
  position: relative;
  background-color: #001529;
  border-right: 0;
  transition: background-color 0.3s, min-width 0.3s, max-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-pro-sider .ant-menu {
  background: transparent;
}
.ant-pro-sider .ant-menu-item {
  transition: none;
}
.ant-pro-sider .ant-menu-submenu-title {
  transition: none;
}
.ant-pro-sider.ant-layout-sider-light .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-pro-sider.ant-layout-sider-light .ant-menu-item-selected a,
.ant-pro-sider.ant-layout-sider-light .ant-menu-item a:hover {
  color: #1890ff;
}
.ant-pro-sider-logo {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 16px;
  cursor: pointer;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-pro-sider-logo > a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
}
.ant-pro-sider-logo img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
.ant-pro-sider-logo h1 {
  display: inline-block;
  height: 32px;
  margin: 0 0 0 12px;
  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  vertical-align: middle;
  animation: pro-layout-title-hide 0.3s;
}
.ant-pro-sider-extra {
  margin-bottom: 16px;
  padding: 0 16px;
}
.ant-pro-sider-extra-no-logo {
  margin-top: 16px;
}
.ant-pro-sider-menu {
  position: relative;
  z-index: 10;
  min-height: 100%;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
}
.ant-pro-sider-menu span.ant-pro-menu-item-title {
  transition: none;
}
.ant-pro-sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* 滚动条滑块 */
}
.ant-pro-sider .ant-layout-sider-children ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.ant-pro-sider .ant-layout-sider-children ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(37, 37, 37, 0.05);
}
.ant-pro-sider .ant-layout-sider-children ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.05);
}
.ant-pro-sider.ant-layout-sider-collapsed .ant-menu-inline-collapsed {
  width: 48px;
}
.ant-pro-sider.ant-layout-sider-collapsed .ant-pro-sider-logo {
  padding: 16px 8px;
}
.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed > .ant-menu-root :not(.ant-pro-sider-link-menu) {
  height: calc(100vh - 48px);
  overflow-y: auto;
}
.ant-pro-sider-light {
  background-color: #fff;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.ant-pro-sider-light .ant-layout-sider-children {
  /* 滚动条滑块 */
}
.ant-pro-sider-light .ant-layout-sider-children ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
}
.ant-pro-sider-light .ant-layout-sider-children ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
}
.ant-pro-sider-light .ant-pro-sider-logo h1 {
  color: #1890ff;
}
.ant-pro-sider-light .ant-menu-light {
  border-right-color: transparent;
}
.ant-pro-sider-light .ant-pro-sider-collapsed-button {
  border-top: 1px solid #f0f0f0;
}
.ant-pro-sider-icon {
  width: 14px;
  vertical-align: baseline;
}
.ant-pro-sider-links {
  width: 100%;
}
.ant-pro-sider-links ul.ant-menu-root {
  height: auto;
}
.ant-pro-sider-collapsed-button {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
.ant-pro-sider-collapsed-button .anticon {
  font-size: 16px;
}
.ant-pro-sider .top-nav-menu li.ant-menu-item {
  height: 100%;
  line-height: 1;
}
.ant-pro-sider .drawer .drawer-content {
  background: #001529;
}
@keyframes pro-layout-title-hide {
  0% {
    display: none;
    opacity: 0;
  }
  80% {
    display: none;
    opacity: 0;
  }
  100% {
    display: unset;
    opacity: 1;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-basicLayout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
.ant-pro-basicLayout .ant-layout-header.ant-pro-fixed-header {
  position: fixed;
  top: 0;
}
.ant-pro-basicLayout-content {
  position: relative;
  margin: 24px;
}
.ant-pro-basicLayout-content .ant-pro-page-container {
  margin: -24px -24px 0;
}
.ant-pro-basicLayout-content-disable-margin {
  margin: 0;
}
.ant-pro-basicLayout-content-disable-margin .ant-pro-page-container {
  margin: 0;
}
.ant-pro-basicLayout-content > .ant-layout {
  max-height: 100%;
}
.ant-pro-basicLayout .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: 100vh;
  overflow: hidden;
  transform: rotate(0);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .tech-page-container {
  height: calc(100vh - 48px - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children {
  min-height: calc(100vh - 48px);
}
.ant-pro-basicLayout .ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-has-header .ant-pro-basicLayout-is-children.ant-pro-basicLayout-fix-siderbar {
  height: calc(100vh - 48px);
}
.ant-pro-top-nav-header {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
  transition: background 0.3s, width 0.2s;
}
.ant-pro-top-nav-header .ant-menu {
  background: transparent;
}
.ant-pro-top-nav-header.light {
  background-color: #fff;
}
.ant-pro-top-nav-header.light .ant-pro-top-nav-header-logo h1 {
  color: rgba(0, 0, 0, 0.85);
}
.ant-pro-top-nav-header.light .anticon {
  color: inherit;
}
.ant-pro-top-nav-header-main {
  display: flex;
  height: 100%;
  padding-left: 16px;
}
.ant-pro-top-nav-header-main-left {
  display: flex;
  min-width: 192px;
}
.ant-pro-top-nav-header .anticon {
  color: #fff;
}
.ant-pro-top-nav-header-logo {
  position: relative;
  min-width: 165px;
  height: 100%;
  overflow: hidden;
}
.ant-pro-top-nav-header-logo img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}
.ant-pro-top-nav-header-logo h1 {
  display: inline-block;
  margin: 0 0 0 12px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  vertical-align: top;
}
.ant-pro-top-nav-header-menu {
  min-width: 0;
}
.ant-pro-top-nav-header-menu .ant-menu.ant-menu-horizontal {
  height: 100%;
  border: none;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-global-footer {
  margin: 48px 0 24px 0;
  padding: 0 16px;
  text-align: center;
}
.ant-pro-global-footer-links {
  margin-bottom: 8px;
}
.ant-pro-global-footer-links a {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-pro-global-footer-links a:not(:last-child) {
  margin-right: 40px;
}
.ant-pro-global-footer-links a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-pro-global-footer-copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-setting-drawer-content {
  position: relative;
  min-height: 100%;
}
.ant-pro-setting-drawer-content .ant-list-item span {
  flex: 1;
}
.ant-pro-setting-drawer-block-checkbox {
  display: flex;
}
.ant-pro-setting-drawer-block-checkbox-item {
  position: relative;
  width: 44px;
  height: 36px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #f0f2f5;
  border-radius: 4px;
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.18);
  cursor: pointer;
}
.ant-pro-setting-drawer-block-checkbox-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 33%;
  height: 100%;
  background-color: #fff;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background-color: #fff;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-realDark {
  background-color: rgba(0, 21, 41, 0.85);
}
.ant-pro-setting-drawer-block-checkbox-item-realDark::before {
  background-color: rgba(0, 21, 41, 0.65);
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-realDark::after {
  background-color: rgba(0, 21, 41, 0.85);
}
.ant-pro-setting-drawer-block-checkbox-item-light::before {
  background-color: #fff;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-light::after {
  background-color: #fff;
}
.ant-pro-setting-drawer-block-checkbox-item-dark::before,
.ant-pro-setting-drawer-block-checkbox-item-side::before {
  z-index: 1;
  background-color: #001529;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-dark::after,
.ant-pro-setting-drawer-block-checkbox-item-side::after {
  background-color: #fff;
}
.ant-pro-setting-drawer-block-checkbox-item-top::before {
  background-color: transparent;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-top::after {
  background-color: #001529;
}
.ant-pro-setting-drawer-block-checkbox-item-mix::before {
  background-color: #fff;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-mix::after {
  background-color: #001529;
}
.ant-pro-setting-drawer-block-checkbox-selectIcon {
  position: absolute;
  right: 6px;
  bottom: 4px;
  color: #1890ff;
  font-weight: bold;
  font-size: 14px;
  pointer-events: none;
}
.ant-pro-setting-drawer-block-checkbox-selectIcon .action {
  color: #1890ff;
}
.ant-pro-setting-drawer-color_block {
  display: inline-block;
  width: 38px;
  height: 22px;
  margin: 4px;
  margin-right: 12px;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
}
.ant-pro-setting-drawer-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
}
.ant-pro-setting-drawer-handle {
  position: absolute;
  top: 240px;
  right: 300px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  background: #1890ff;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  pointer-events: auto;
}
.ant-pro-setting-drawer-production-hint {
  margin-top: 16px;
  font-size: 12px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-setting-drawer-content {
  position: relative;
  min-height: 100%;
}
.ant-pro-setting-drawer-content .ant-list-item span {
  flex: 1;
}
.ant-pro-setting-drawer-block-checkbox {
  display: flex;
}
.ant-pro-setting-drawer-block-checkbox-item {
  position: relative;
  width: 44px;
  height: 36px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #f0f2f5;
  border-radius: 4px;
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.18);
  cursor: pointer;
}
.ant-pro-setting-drawer-block-checkbox-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 33%;
  height: 100%;
  background-color: #fff;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background-color: #fff;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-realDark {
  background-color: rgba(0, 21, 41, 0.85);
}
.ant-pro-setting-drawer-block-checkbox-item-realDark::before {
  background-color: rgba(0, 21, 41, 0.65);
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-realDark::after {
  background-color: rgba(0, 21, 41, 0.85);
}
.ant-pro-setting-drawer-block-checkbox-item-light::before {
  background-color: #fff;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-light::after {
  background-color: #fff;
}
.ant-pro-setting-drawer-block-checkbox-item-dark::before,
.ant-pro-setting-drawer-block-checkbox-item-side::before {
  z-index: 1;
  background-color: #001529;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-dark::after,
.ant-pro-setting-drawer-block-checkbox-item-side::after {
  background-color: #fff;
}
.ant-pro-setting-drawer-block-checkbox-item-top::before {
  background-color: transparent;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-top::after {
  background-color: #001529;
}
.ant-pro-setting-drawer-block-checkbox-item-mix::before {
  background-color: #fff;
  content: '';
}
.ant-pro-setting-drawer-block-checkbox-item-mix::after {
  background-color: #001529;
}
.ant-pro-setting-drawer-block-checkbox-selectIcon {
  position: absolute;
  right: 6px;
  bottom: 4px;
  color: #1890ff;
  font-weight: bold;
  font-size: 14px;
  pointer-events: none;
}
.ant-pro-setting-drawer-block-checkbox-selectIcon .action {
  color: #1890ff;
}
.ant-pro-setting-drawer-color_block {
  display: inline-block;
  width: 38px;
  height: 22px;
  margin: 4px;
  margin-right: 12px;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
}
.ant-pro-setting-drawer-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
}
.ant-pro-setting-drawer-handle {
  position: absolute;
  top: 240px;
  right: 300px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  background: #1890ff;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  pointer-events: auto;
}
.ant-pro-setting-drawer-production-hint {
  margin-top: 16px;
  font-size: 12px;
}
.ant-pro-setting-drawer-content .theme-color {
  margin-top: 16px;
  overflow: hidden;
}
.ant-pro-setting-drawer-content .theme-color .theme-color-title {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 22px;
}
.ant-pro-setting-drawer-content .theme-color .theme-color-block {
  float: left;
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 8px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
}

